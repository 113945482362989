import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/@next/providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper-bundle.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/modules/pagination/pagination.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/modules/navigation/navigation.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/avatar.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-phone-number-input/style.css");
