// React
import { useMemo, ReactNode } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  Theme,
} from "@mui/material/styles";

// project import
import Typography, { ExtendedTypographyOptions } from "./typography";
import componentStyleOverrides from "./compStyleOverride";

import { TypographyOptions } from "@mui/material/styles/createTypography";
import Palette from "./palette";

// import { useConfig } from 'src/hooks/useConfig';

/* --------------------------------------------------- MAIN Theme Content -------------------------------------------------- */

// Interface for Props.
interface Props {
  children: ReactNode;
}

export default function ThemeCustomization({ children }: Props) {
  // const { pmode } = useConfig();
  const pmode = "light";
  const fontFamily = "Inter";

  const theme: Theme = useMemo<Theme>(() => Palette(pmode), [pmode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography: ExtendedTypographyOptions = useMemo<ExtendedTypographyOptions>(
    () => Typography(fontFamily),
    [fontFamily]
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1380,
          xl: 1536,
        },
      },
    }),
    [theme, themeTypography]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = useMemo(() => componentStyleOverrides(themes), [themes]);

  return (
   
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
   
  );
}
