"use client";

import React from "react";
import { createStore } from "@store";
import { getCookie } from "cookies-next";
import Script from "next/script";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import MuiThemeProvider from "@next/theme";
import ToastifyContainer from "@next/components/molecules/toastify-container/toastify-container";
import { MainLayout } from "@components/templates";

const { store, persistor } = createStore();

export const Providers = ({ children }: { children: React.ReactNode }) => {
  const cookieValue = getCookie("localConsent");

  return (
    <Provider {...{ store }}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Script id="gtag">
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('consent', 'default', {
'ad_storage': 'denied',
'analytics_storage': 'denied'
});`}
      </Script>
      <Script id="google-tag-manager">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W8Q5RGHR');`}</Script>

      <Script
        id="google-adsense"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3699749149091198"
        crossOrigin="anonymous"
        strategy="lazyOnload"
      />

      {cookieValue == "true" && (
        <Script
          id="consupd"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
      `,
          }}
        />
      )}
      <MuiThemeProvider>
        <ToastifyContainer />
        <MainLayout>{children}</MainLayout>
      </MuiThemeProvider>
      {/* </PersistGate> */}
    </Provider>
  );
};
