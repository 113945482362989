export interface commonColorType  {
  black: string;
  white: string;
  grayBlue: {
    50: string;
    100: string;
    400: string;
    300: string;
    200: string;
    600: string;
    500: string;
    700: string;
    900: string;
  };
  grayCool: {
    100: string;
    25: string;
    50: string;
    200: string;
    300: string;
    400: string;
    500: string;
    800: string;
    900: string;
    600: string;
    700: string;
  };
  grayModern: {
    50: string;
    200: string;
    25: string;
    100: string;
    600: string;
    300: string;
    400: string;
    700: string;
    500: string;
    800: string;
    900: string;
  };
  grayNeutral: {
    50: string;
    25: string;
    100: string;
    200: string;
    300: string;
    400: string;
    700: string;
    600: string;
    500: string;
    900: string;
    800: string;
  };
  grayIron: {
    25: string;
    200: string;
    100: string;
    400: string;
    50: string;
    300: string;
    500: string;
    700: string;
    600: string;
    800: string;
    900: string;
  };
  grayTrue: {
    25: string;
    50: string;
    200: string;
    300: string;
    100: string;
    400: string;
    600: string;
    500: string;
    700: string;
    900: string;
    800: string;
  };
  grayWarm: {
    25: string;
    50: string;
    100: string;
    300: string;
    200: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  moss: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  greenLight: {
    100: string;
    200: string;
    25: string;
    300: string;
    50: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  green: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  teal: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  cyan: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  blueLight: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  blue: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  blueDark: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  indigo: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  violet: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  purple: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  fuchsia: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  pink: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  ros: {
    25: string;
    50: string;
    200: string;
    300: string;
    500: string;
    100: string;
    400: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  orangeDark: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  orange: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  yellow: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  avatarUserSquareLanaSteinerColorBackground: string;
  avatarUserSquareDemiWilkinsonColorBackground: string;
  avatarUserSquareCandiceWuColorBackground: string;
  avatarUserSquareOliviaRhyeColorBackground: string;
  avatarUserSquarePhoenixBakerColorBackground: string;
  avatarUserSquareNataliCraigColorBackground: string;
  avatarUserSquareDrewCanoColorBackground: string;
  avatarUserSquareOrlandoDiggsColorBackground: string;
  avatarUserSquareAndiLaneColorBackground: string;
  avatarUserSquareKateMorrisonColorBackground: string;
  avatarUserSquareKorayOkumusColorBackground: string;
  avatarUserSquareEveLeroyColorBackground: string;
  avatarUserSquareAvaWrightColorBackground: string;
  avatarUserSquareZahirMaysColorBackground: string;
  avatarUserSquareJoshuaWilsonColorBackground: string;
  avatarUserSquareReneWellsColorBackground: string;
  avatarUserSquareAnaiahWhittenColorBackground: string;
  avatarUserSquareLoriBrysonColorBackground: string;
  avatarUserSquareKatherineMossColorBackground: string;
  avatarUserSquareNoahPierreColorBackground: string;
  avatarUserSquareMollieHallColorBackground: string;
  avatarUserSquareEduardFranzeColorBackground: string;
  avatarUserSquareEvaBondColorBackground: string;
  avatarUserSquareAlecWhittenColorBackground: string;
  avatarUserSquareSophiaPerezColorBackground: string;
  avatarUserSquareLyleKauffmanColorBackground: string;
  avatarUserSquareLucyBondColorBackground: string;
  avatarUserSquareAlisaHesterColorBackground: string;
  avatarUserSquareRosaleeMelvinColorBackground: string;
  avatarUserSquareKellyWilliamsColorBackground: string;
  avatarUserSquareAnitaCruzColorBackground: string;
  avatarUserSquareLokiBrightColorBackground: string;
  avatarUserSquareAliahLaneColorBackground: string;
  avatarUserSquareKariRasmussenColorBackground: string;
  avatarUserSquareNalaGoinsColorBackground: string;
};
export type ThemeColors = {
  
  primary: {
    25: string;
    50: string;
    200: string;
    100: string;
    300: string;
    400: string;
    500: string;
    700: string;
    600: string;
    800: string;
    900: string;
  };
  error: {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    600: string;
    500: string;
    800: string;
    700: string;
    900: string;
  };
  warning: {
    25: string;
    50: string;
    100: string;
    400: string;
    300: string;
    200: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  success: {
    50: string;
    100: string;
    200: string;
    400: string;
    300: string;
    500: string;
    600: string;
    800: string;
    700: string;
  };
  grey: {
    25: string;
    50: string;
    100: string;
    200: string;
    400: string;
    300: string;
    500: string;
    800: string;
    600: string;
    700: string;
    900: string;
  };
  common: commonColorType
 
};
export const themeColors = (): ThemeColors => {
  return {
   
    primary: {
      25: "#fffbfd",
      50: "#fff6fb",
      200: "#fdcde4",
      100: "#feeaf4",
      300: "#fba5ce",
      400: "#f97bb8",
      500: "#f854a2",
      700: "#d12779",
      600: "#f62e8e",
      800: "#af2165",
      900: "#8c1a51",
    },
    error: {
      25: "#fffbfa",
      50: "#fef3f2",
      100: "#fee4e2",
      200: "#fecdca",
      300: "#fda29b",
      400: "#f97066",
      600: "#d92d20",
      500: "#f04438",
      800: "#912018",
      700: "#b42318",
      900: "#7a271a",
    },
    warning: {
      25: "#fffcf5",
      50: "#fffaeb",
      100: "#fef0c7",
      400: "#fdb022",
      300: "#fec84b",
      200: "#fedf89",
      500: "#f79009",
      600: "#dc6803",
      700: "#b54708",
      800: "#93370d",
      900: "#7a2e0e",
    },
    success: {
      50: "#ecfdf3",
      100: "#d1fadf",
      200: "#a6f4c5",
      400: "#32d583",
      300: "#6ce9a6",
      500: "#12b76a",
      600: "#039855",
      800: "#05603a",
      700: "#027a48",
    },
    grey: {
      25: " #fcfcfd",
      50: "#f9fafb",
      100: "#f2f4f7",
      200: "#eaecf0",
      400: "#98a2b3",
      300: "#d0d5dd",
      500: "#667085",
      800: "#1d2939",
      600: "#475467",
      700: "#344054",
      900: "#101828",
    },
    common: {
      black: "#000",
      white: "#fff",
      grayBlue: {
        50: '#f8f9fc',
        100: '#eaecf5',
        400: '#717bbc',
        300: '#b3b8db',
        200: '#d5d9eb',
        600: '#3e4784',
        500: '#4e5ba6',
        700: '#363f72',
        900: '#101323',
      },
      grayCool: {
        100: '#eff1f5',
        25: '#fcfcfd',
        50: '#f9f9fb',
        200: '#dcdfea',
        300: '#b9c0d4',
        400: '#7d89b0',
        500: '#5d6b98',
        800: '#30374f',
        900: '#111322',
        600: '#4a5578',
        700: '#404968',
      },
      grayModern: {
        50: '#f8fafc',
        200: '#e3e8ef',
        25: '#fcfcfd',
        100: '#eef2f6',
        600: '#4b5565',
        300: '#cdd5df',
        400: '#9aa4b2',
        700: '#364152',
        500: '#697586',
        800: '#202939',
        900: '#121926',
      },
      grayNeutral: {
        50: '#f9fafb',
        25: '#fcfcfd',
        100: '#f3f4f6',
        200: '#e5e7eb',
        300: '#d2d6db',
        400: '#9da4ae',
        700: '#384250',
        600: '#4d5761',
        500: '#6c737f',
        900: '#111927',
        800: '#1f2a37',
      },
      grayIron: {
        25: '#fcfcfc',
        200: '#e4e4e7',
        100: '#f4f4f5',
        400: '#a0a0ab',
        50: '#fafafa',
        300: '#d1d1d6',
        500: '#70707b',
        700: '#3f3f46',
        600: '#51525c',
        800: '#26272b',
        900: '#18181b',
      },
      grayTrue: {
        25: '#fcfcfc',
        50: '#fafafa',
        200: '#e5e5e5',
        300: '#d6d6d6',
        100: '#f5f5f5',
        400: '#a3a3a3',
        600: '#525252',
        500: '#737373',
        700: '#424242',
        900: '#141414',
        800: '#292929',
      },
      grayWarm: {
        25: '#fdfdfc',
        50: '#fafaf9',
        100: '#f5f5f4',
        300: '#d7d3d0',
        200: '#e7e5e4',
        400: '#a9a29d',
        500: '#79716b',
        600: '#57534e',
        700: '#44403c',
        800: '#292524',
        900: '#1c1917',
      },
      moss: {
        25: "#fafdf7",
        50: "#f5fbee",
        100: "#e6f4d7",
        200: "#ceeab0",
        300: "#acdc79",
        400: "#86cb3c",
        500: "#66c61c",
        600: "#4ca30d",
        700: "#3b7c0f",
        800: "#326212",
        900: "#2b5314",
      },
      greenLight: {
        100: "#e3fbcc",
        200: "#d0f8ab",
        25: "#fafef5",
        300: "#a6ef67",
        50: "#f3fee7",
        400: "#85e13a",
        500: "#66c61c",
        600: "#4ca30d",
        700: "#3b7c0f",
        800: "#326212",
        900: "#2b5314",
      },
      green: {
        25: "#f6fef9",
        50: "#eff8ff",
        100: "#d1e9ff",
        200: "#b2ddff",
        400: "#53b1fd",
        300: "#84caff",
        500: "#2e90fa",
        600: "#1570ef",
        700: "#175cd3",
        800: "#1849a9",
        900: "#194185",
      },
      teal: {
        25: "#f6fefc",
        50: "#f0fdf9",
        100: "#ccfbef",
        200: "#99f6e0",
        300: "#5fe9d0",
        400: "#2ed3b7",
        500: "#15b79e",
        600: "#0e9384",
        700: "#107569",
        800: "#125d56",
        900: "#134e48",
      },
      cyan: {
        25: "#f5feff",
        50: "#ecfdff",
        100: "#cff9fe",
        200: "#a5f0fc",
        300: "#67e3f9",
        400: "#02ce",
        500: "#06aed4",
        600: "#088ab2",
        700: "#0e7090",
        800: "#155b75",
        900: "#164c63",
      },
      blueLight: {
        25: "#f5fbff",
        50: "#f0f9ff",
        100: "#e0f2fe",
        200: "#b9e6fe",
        300: "#7cd4fd",
        400: "#36bffa",
        500: "#0ba5ec",
        600: "#0086c9",
        700: "#026aa2",
        800: "#065986",
        900: "#0b4a6f",
      },
      blue: {
        25: "#f5faff",
        50: "#eff8ff",
        100: "#d1e9ff",
        200: "#b2ddff",
        400: "#53b1fd",
        300: "#84caff",
        500: "#2e90fa",
        600: "#1570ef",
        700: "#175cd3",
        800: "#1849a9",
        900: "#194185",
      },
      blueDark: {
        25: "#f5f8ff",
        50: "#eff4ff",
        100: "#d1e0ff",
        200: "#b2ccff",
        300: "#84adff",
        400: "#2970ff",
        500: "#528bff",
        600: "#155eef",
        700: "#004eeb",
        800: "#0040c1",
        900: "#00359e",
      },
      indigo: {
        25: "#f5f8ff",
        50: "#eef4ff",
        100: "#e0eaff",
        200: "#c7d7fe",
        400: "#8098f9",
        300: "#a4bcfd",
        500: "#6172f3",
        600: "#444ce7",
        700: "#3538cd",
        800: "#2d31a6",
        900: "#2d3282",
      },
      violet: {
        25: "#fbfaff",
        50: "#f5f3ff",
        100: "#ece9fe",
        200: "#ddd6fe",
        500: "#875bf7",
        400: "#a48afb",
        300: "#c3b5fd",
        600: "#7839ee",
        700: "#6927da",
        800: "#5720b7",
        900: "#491c96",
      },
      purple: {
        25: "#fafaff",
        50: "#f4f3ff",
        100: "#ebe9fe",
        200: "#d9d6fe",
        300: "#eeaafd",
        400: "#e478fa",
        500: "#d444f1",
        600: "#ba24d5",
        700: "#9f1ab1",
        800: "#821890",
        900: "#6f1877",
      },
      fuchsia: {
        25: "#fefaff",
        50: "#fdf4ff",
        100: "#fbe8ff",
        200: "#f6d0fe",
        300: "#eeaafd",
        400: "#e478fa",
        500: "#d444f1",
        600: "#ba24d5",
        700: "#9f1ab1",
        800: "#821890",
        900: "#6f1877",
      },
      pink: {
        25: "#fef6fb",
        50: "#fdf2fa",
        100: "#fce7f6",
        200: "#fcceee",
        300: "#faa7e0",
        400: "#f670c7",
        500: "#ee46bc",
        600: "#dd2590",
        700: "#c11574",
        800: "#9e165f",
        900: "#851651",
      },
      ros: {
        25: "#fff5f6",
        50: "#fff1f3",
        200: "#fecdd6",
        300: "#fea3b4",
        500: "#f63d68",
        100: "#ffe4e8",
        400: "#fd6f8e",
        600: "#e31b54",
        700: "#c01048",
        800: "#a11043",
        900: "#89123e",
      },
      orangeDark: {
        25: "#fff9f5",
        50: "#fff4ed",
        100: "#ffe6d5",
        200: "#ffd6ae",
        300: "#ff9c66",
        400: "#ff692e",
        500: "#ff4405",
        600: "#e62e05",
        700: "#bc1b06",
        800: "#97180c",
        900: "#771a0d",
      },
      orange: {
        25: "#fefaf5",
        50: "#fef6ee",
        100: "#fdead7",
        200: "#f9dbaf",
        300: "#f7b27a",
        400: "#f38744",
        500: "#ef6820",
        600: "#e04f16",
        700: "#b93815",
        800: "#932f19",
        900: "#772917",
      },
      yellow: {
        25: "#fefdf0",
        50: "#fefbe8",
        100: "#fef7c3",
        200: "#feee95",
        300: "#fde272",
        400: "#fac515",
        500: "#eaaa08",
        600: "#ca8504",
        700: "#a15c07",
        800: "#854a0e",
        900: "#713b12",
      },
      avatarUserSquareLanaSteinerColorBackground: "#d4b5ad",
      avatarUserSquareDemiWilkinsonColorBackground: "#bea887",
      avatarUserSquareCandiceWuColorBackground: "#a2a8cd",
      avatarUserSquareOliviaRhyeColorBackground: "#c7b9da",
      avatarUserSquarePhoenixBakerColorBackground: "#aa9c75",
      avatarUserSquareNataliCraigColorBackground: "#d1baa9",
      avatarUserSquareDrewCanoColorBackground: "#d1dfc3",
      avatarUserSquareOrlandoDiggsColorBackground: "#cfc3a7",
      avatarUserSquareAndiLaneColorBackground: "#d2c7ac",
      avatarUserSquareKateMorrisonColorBackground: "#dbc0dd",
      avatarUserSquareKorayOkumusColorBackground: "#d4b2af",
      avatarUserSquareEveLeroyColorBackground: "#dab9bb",
      avatarUserSquareAvaWrightColorBackground: "#c9ba9c",
      avatarUserSquareZahirMaysColorBackground: "#c3c7df",
      avatarUserSquareJoshuaWilsonColorBackground: "#d2dbbd",
      avatarUserSquareReneWellsColorBackground: "#dfcc9f",
      avatarUserSquareAnaiahWhittenColorBackground: "#ccc0dd",
      avatarUserSquareLoriBrysonColorBackground: "#d9b9bb",
      avatarUserSquareKatherineMossColorBackground: "#a2a8cd",
      avatarUserSquareNoahPierreColorBackground: "#d4afbd",
      avatarUserSquareMollieHallColorBackground: "#cb9fa2",
      avatarUserSquareEduardFranzeColorBackground: "#dbd0bd",
      avatarUserSquareEvaBondColorBackground: "#dbc0dd",
      avatarUserSquareAlecWhittenColorBackground: "#abb677",
      avatarUserSquareSophiaPerezColorBackground: "#b6cdd8",
      avatarUserSquareLyleKauffmanColorBackground: "#d2b1ac",
      avatarUserSquareLucyBondColorBackground: "#dbc0dd",
      avatarUserSquareAlisaHesterColorBackground: "#d4afbd",
      avatarUserSquareRosaleeMelvinColorBackground: "#dbcabd",
      avatarUserSquareKellyWilliamsColorBackground: "#d8c7b6",
      avatarUserSquareAnitaCruzColorBackground: "#c2c7b8",
      avatarUserSquareLokiBrightColorBackground: "#b9cfd0",
      avatarUserSquareAliahLaneColorBackground: "#d7c0dd",
      avatarUserSquareKariRasmussenColorBackground: "#a9b58d",
      avatarUserSquareNalaGoinsColorBackground: "#c0c6dd",
    },
   
  };
};
