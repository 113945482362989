import { TypographyOptions } from "@mui/material/styles/createTypography";

interface Typography {
  fontFamily: string;
  fontSize: string;
  fontWeight: number | string;
  fontStretch: string;
  fontStyle: string;
  lineHeight: number;
  letterSpacing: string;
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    // Typography display variants
    Display_2xlBold: true;
    Display_2xlSemibold: true;
    Display_2xlMedium: true;
    Display_2xlRegular: true;
    Display_xlBold: true;
    Display_xlSemibold: true;
    Display_xlMedium: true;
    Display_xlNormal: true;
    Display_lgBold: true;
    Display_lgSemibold: true;
    Display_lgMedium: true;
    Display_lgNormal: true;
    Display_mdBold: true;
    Display_mdSemibold: true;
    Display_mdMedium: true;
    Display_mdNormal: true;
    Display_smBold: true;
    Display_smSemibold: true;
    Display_smMedium: true;
    Display_sm_Medium_italic: true;
    Display_smNormal: true;
    Display_xsBold: true;
    Display_xsSemibold: true;
    Display_xsMedium: true;
    Display_xsMedium_italic: true;
    Display_xsNormal: true;
    Text_xlBold: true;
    Text_xlBold_italic: true;
    Text_xlSemibold: true;
    Text_xlSemibold_italic: true;
    Text_xlMedium: true;
    Text_xlMedium_italic: true;
    Text_xlNormal_italic: true;
    Text_xlNormal_underlined: true;
    Text_xlNormal: true;
    Text_lgBold: true;
    Text_lgBold_italic: true;
    Text_lgSemibold: true;
    Text_lgSemibold_italic: true;
    Text_lgMedium: true;
    // Text_lgMedium_underlined: true;
    Text_lgMedium_italic: true;
    Text_lgNormal_italic: true;
    Text_lgRegular_underlined: true;
    Text_lgNormal: true;
    Text_mdBold: true;
    Text_mdBold_italic: true;
    Text_mdSemibold: true;
    Text_mdSemibold_italic: true;
    Text_mdMedium: true;
    // Text_mdMedium_underlined: true;
    Text_mdMedium_italic: true;
    Text_mdNormal_italic: true;
    Text_mdNormal: true;
    // Text_mdNormal_underlined: true;
    Text_smBold: true;
    Text_smSemibold: true;
    Text_smMedium: true;
    Text_smNormal: true;
    // Text_smNormal_underlined: true;
    Text_mdRegular_underlined: true;
    Text_smBold_italic: true;
    Text_smSemibold_italic: true;
    Text_smMedium_italic: true;
    Text_smNormal_italic: true;
    Text_smRegular_underlined: true;
     Text_xsBold: true;
    Text_xsSemibold: true;
    Text_xsMedium: true;
    Text_xsNormal: true;
    //title
    Title_2xlBold: true;
    Title_2xlSemibold: true;
    Title_2xlMedium: true;
    Title_2xlRegular: true;
    Title_xlargeBold: true;
    Title_xlargeSemibold: true;
    Title_xlargeMedium: true;
    Title_xlargeRegular: true;
    Title_largeBold: true;
    Title_largeSemibold: true;
    Title_largeMedium: true;
    Title_largeRegular: true;
    Title_mediumBold: true;
    Title_mediumSemibold: true;
    Title_mediumMedium: true;
    Title_mediumRegular: true;
    Title_smallBold: true;
    Title_smallSemibold: true;
    Title_smallMedium: true;
    Title_smallRegular: true;
    Title_xsmallBold: true;
    Title_xsmallSemibold: true;
    Title_xsmallMedium: true;
    Title_xsmallRegular: true;
    //Body
    Body_xlargeBold: true,
  Body_xlargeSemibold: true,
  Body_xlargeMedium: true,
  Body_xlargeRegular: true,
  Body_largeBold: true,
  Body_largeSemibold: true,
  Body_largeMedium: true,
  Body_largeRegular: true,
  Body_mediumBold: true,
  Body_mediumSemibold: true,
  Body_mediumMedium: true,
  Body_mediumRegular: true,
  Body_smallBold: true,
  Body_smallSemibold: true,
  Body_smallMedium: true,
  Body_smallRegular: true,
  Body_xsmallBold: true,
  Body_xsmallSemibold: true,
  Body_xsmallMedium: true,
  Body_xsmallRegular: true,
  heading: true,
  supporting_text:true,
  heading_md: true,
  supporting_text_md:true
  }
}
export interface ExtendedTypographyOptions extends TypographyOptions {
  Display_2xlBold: React.CSSProperties;
  Display_2xlSemibold: React.CSSProperties;
  Display_2xlMedium: React.CSSProperties;
  Display_2xlRegular: React.CSSProperties;
  Display_xlBold: React.CSSProperties;
  Display_xlSemibold: React.CSSProperties;
  Display_xlMedium: React.CSSProperties;
  Display_xlNormal: React.CSSProperties;
  Display_lgBold: React.CSSProperties;
  Display_lgSemibold: React.CSSProperties;
  Display_lgMedium: React.CSSProperties;
  Display_lgNormal: React.CSSProperties;
  Display_mdBold: React.CSSProperties;
  Display_mdSemibold: React.CSSProperties;
  Display_mdMedium: React.CSSProperties;
  Display_mdNormal: React.CSSProperties;
  Display_smBold: React.CSSProperties;
  Display_smSemibold: React.CSSProperties;
  Display_smMedium: React.CSSProperties;
  Display_sm_Medium_italic: React.CSSProperties;
  Display_smNormal: React.CSSProperties;
  Display_xsBold: React.CSSProperties;
  Display_xsSemibold: React.CSSProperties;
  Display_xsMedium: React.CSSProperties;
  Display_xsMedium_italic: React.CSSProperties;
  Display_xsNormal: React.CSSProperties;
  Text_xlBold: React.CSSProperties;
  Text_xlBold_italic: React.CSSProperties;
  Text_xlSemibold: React.CSSProperties;
  Text_xlSemibold_italic: React.CSSProperties;
  Text_xlMedium: React.CSSProperties;
  Text_xlMedium_italic: React.CSSProperties;
  Text_xlNormal_italic: React.CSSProperties;
  Text_xlNormal_underlined: React.CSSProperties;
  Text_xlNormal: React.CSSProperties;
  Text_lgBold: React.CSSProperties;
  Text_lgBold_italic: React.CSSProperties;
  Text_lgSemibold: React.CSSProperties;
  Text_lgSemibold_italic: React.CSSProperties;
  Text_lgMedium: React.CSSProperties;
  // Text_lgMedium_underlined: React.CSSProperties;
  Text_lgMedium_italic: React.CSSProperties;
  Text_lgNormal_italic: React.CSSProperties;
  Text_lgRegular_underlined: React.CSSProperties;
  Text_lgNormal: React.CSSProperties;
  Text_mdBold: React.CSSProperties;
  Text_mdBold_italic: React.CSSProperties;
  Text_mdSemibold: React.CSSProperties;
  Text_mdSemibold_italic: React.CSSProperties;
  Text_mdMedium: React.CSSProperties;
  // Text_mdMedium_underlined: React.CSSProperties;
  Text_mdMedium_italic: React.CSSProperties;
  Text_mdNormal_italic: React.CSSProperties;
  Text_mdNormal: React.CSSProperties;
  // Text_mdNormal_underlined: React.CSSProperties;
  Text_smBold: React.CSSProperties;
  Text_smSemibold: React.CSSProperties;
  Text_smMedium: React.CSSProperties;
  Text_smNormal: React.CSSProperties;
  // Text_smNormal_underlined: React.CSSProperties;
  Text_mdRegular_underlined: React.CSSProperties;
  Text_smBold_italic: React.CSSProperties;
  Text_smSemibold_italic: React.CSSProperties;
  Text_smMedium_italic: React.CSSProperties;
  Text_smNormal_italic: React.CSSProperties;
  Text_smRegular_underlined: React.CSSProperties;
  Text_xsBold: React.CSSProperties;
  Text_xsSemibold: React.CSSProperties;
  Text_xsMedium: React.CSSProperties;
  Text_xsNormal: React.CSSProperties;
  //title
  Title_2xlBold: React.CSSProperties;
  Title_2xlSemibold: React.CSSProperties;
  Title_2xlMedium: React.CSSProperties;
  Title_2xlRegular: React.CSSProperties;
  Title_xlargeBold: React.CSSProperties;
  Title_xlargeSemibold: React.CSSProperties;
  Title_xlargeMedium: React.CSSProperties;
  Title_xlargeRegular: React.CSSProperties;
  Title_largeBold: React.CSSProperties;
  Title_largeSemibold: React.CSSProperties;
  Title_largeMedium: React.CSSProperties;
  Title_largeRegular: React.CSSProperties;
  Title_mediumBold: React.CSSProperties;
  Title_mediumSemibold: React.CSSProperties;
  Title_mediumMedium: React.CSSProperties;
  Title_mediumRegular: React.CSSProperties;
  Title_smallBold: React.CSSProperties;
  Title_smallSemibold: React.CSSProperties;
  Title_smallMedium: React.CSSProperties;
  Title_smallRegular: React.CSSProperties;
  Title_xsmallBold: React.CSSProperties;
  Title_xsmallSemibold: React.CSSProperties;
  Title_xsmallMedium: React.CSSProperties;
  Title_xsmallRegular: React.CSSProperties;
  //Body
  Body_xlargeBold: React.CSSProperties;
  Body_xlargeSemibold: React.CSSProperties;
  Body_xlargeMedium: React.CSSProperties;
  Body_xlargeRegular: React.CSSProperties;
  Body_largeBold: React.CSSProperties;
  Body_largeSemibold: React.CSSProperties;
  Body_largeMedium: React.CSSProperties;
  Body_largeRegular: React.CSSProperties;
  Body_mediumBold: React.CSSProperties;
  Body_mediumSemibold: React.CSSProperties;
  Body_mediumMedium: React.CSSProperties;
  Body_mediumRegular: React.CSSProperties;
  Body_smallBold: React.CSSProperties;
  Body_smallSemibold: React.CSSProperties;
  Body_smallMedium: React.CSSProperties;
  Body_smallRegular: React.CSSProperties;
  Body_xsmallBold: React.CSSProperties;
  Body_xsmallSemibold: React.CSSProperties;
  Body_xsmallMedium: React.CSSProperties;
  Body_xsmallRegular: React.CSSProperties;
  heading: React.CSSProperties;
  supporting_text: React.CSSProperties;
  heading_md: React.CSSProperties;
  supporting_text_md: React.CSSProperties
}

const typographyTheme = (fontFamily: string): ExtendedTypographyOptions => ({
  fontFamily,
  Display_2xlBold: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Display_2xlSemibold: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Display_2xlMedium: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Display_2xlRegular: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Display_xlBold: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Display_xlSemibold: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Display_xlMedium: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Display_xlNormal: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Display_lgBold: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Display_lgSemibold: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Display_lgMedium: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Display_lgNormal: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Display_mdBold: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Display_mdSemibold: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Display_mdMedium: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Display_mdNormal: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Display_smBold: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Display_smSemibold: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Display_smMedium: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Display_sm_Medium_italic: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.47,
    letterSpacing: "normal",
  },
  Display_smNormal: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Display_xsBold: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Display_xsSemibold: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Display_xsMedium: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Display_xsMedium_italic: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Display_xsNormal: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Text_xlBold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlBold_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlSemibold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlSemibold_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlMedium: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlMedium_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlNormal_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlNormal_underlined: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xlNormal: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_lgBold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: "normal",
  },
  Text_lgBold_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.6,
    letterSpacing: "normal",
  },
  Text_lgSemibold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: "normal",
  },
  Text_lgSemibold_italic: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.6,
    letterSpacing: "normal",
  },
  Text_lgMedium: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Text_lgMedium_italic: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Text_lgNormal_italic: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Text_lgRegular_underlined: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Text_lgNormal: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Text_mdBold: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdBold_italic: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdSemibold: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdSemibold_italic: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdMedium: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdMedium_italic: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdNormal_italic: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdRegular_underlined: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_mdNormal: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_smBold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smBold_italic: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smSemibold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smSemibold_italic: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smMedium: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smMedium_italic: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smNormal_italic: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_smRegular_underlined: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
 
  Text_smNormal: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Text_xsBold:{
    fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.5,
  letterSpacing: "normal",
  },
  Text_xsSemibold:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xsMedium:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Text_xsNormal:{
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Title_2xlBold: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Title_2xlSemibold: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Title_2xlMedium: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Title_2xlRegular: {
    fontFamily: "Inter",
    fontSize: "72px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-1.44px",
  },
  Title_xlargeBold: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Title_xlargeSemibold: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Title_xlargeMedium: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Title_xlargeRegular: {
    fontFamily: "Inter",
    fontSize: "60px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-1.2px",
  },
  Title_largeBold: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Title_largeSemibold: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Title_largeMedium: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Title_largeRegular: {
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "-0.96px",
  },
  Title_mediumBold: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Title_mediumSemibold: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Title_mediumMedium: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Title_mediumRegular: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "-0.72px",
  },
  Title_smallBold: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Title_smallSemibold: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Title_smallMedium: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Title_smallRegular: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
  },
  Title_xsmallBold: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Title_xsmallSemibold: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Title_xsmallMedium: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Title_xsmallRegular: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
  },
  Body_xlargeBold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xlargeSemibold: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xlargeMedium: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xlargeRegular: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_largeBold: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Body_largeSemibold: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Body_largeMedium: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Body_largeRegular: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
  },
  Body_mediumBold: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_mediumSemibold: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_mediumMedium: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_mediumRegular: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_smallBold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Body_smallSemibold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Body_smallMedium: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Body_smallRegular: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
  },
  Body_xsmallBold: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xsmallSemibold: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xsmallMedium: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  Body_xsmallRegular: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  heading:{
    fontSize:"52px",
    lineHeight:"67px",
    fontWeight:600,
    fontStyle:"normal",
    letterSpacing: "-1.04px"
  },
  supporting_text:{
    fontSize:"20px",
    lineHeight:"30px",
    fontWeight:400,
    fontStyle:"normal"
  },
  heading_md:{
    fontSize:"42px",
    lineHeight:"52px",
    fontWeight:600,
    fontStyle:"normal",
  },
  supporting_text_md:{
    fontSize:"18px",
    lineHeight:"28px",
    fontWeight:400,
    fontStyle:"normal"
  }
});
export default typographyTheme;
