// MUI Import
import {  PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// Style import
import { commonColorType, themeColors } from "./themeColors";

/* --------------------------------------------------- MAIN Function -------------------------------------------------- */

declare module "@mui/material/styles" {
  interface TypographyOptions {
    dark?: React.CSSProperties["color"];
  }
  interface PaletteColorOptions {
    light?: string;
  main?: string;
  dark?: string;
    200?: string;
    700?: string;
  }
  interface CommonColors extends commonColorType {
   
  }
  interface PaletteOptions {
    primary?: Partial<PaletteColorOptions>;
  }

}

const Palette = (pmode: PaletteMode) => {
  const colors = themeColors();
  return createTheme({
    palette: {
      mode: pmode,
      primary: {
        light: colors.primary["300"],
        main: colors.primary["400"],
        dark: colors.primary["600"],
        200:colors.primary["200"],
        700:colors.primary["700"]
      },
      error: {
        light: colors.error["300"],
        main: colors.error["400"],
        dark: colors.error["600"],
      },
      warning: {
        light: colors.warning["300"],
        main: colors.warning["400"],
        dark: colors.warning["600"],
      },
      success: {
        light: colors.success["300"],
        main: colors.success["400"],
        dark: colors.success["600"],
      },
      grey: {
        ...colors.grey,
      },
      text: {
        primary: colors.grey["900"],
        secondary: colors.grey["500"],
      },
      background: {
        paper: colors.common["white"],
        default: colors.common["white"],
      },
      common: colors.common
    },
  });
};

export default Palette;
