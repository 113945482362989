// MUI imports
import { Theme } from "@mui/material/styles";

/* --------------------------------------------------- MAIN Function -------------------------------------------------- */

export default function componentStyleOverrides(theme: Theme) {
  return {
   
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius:"8px",
          fontSize:"16px",
          fontWeight:500,
          letterSpacing:"normal",
          lineHeight:1.5,
        },
        contained: {
          backgroundColor: theme.palette.primary.dark,
          border:`1px solid ${theme.palette.primary.dark}`,
          color: '#fff',
          boxShadow:"none",
          "&:hover":{
            backgroundColor: theme.palette.primary.dark,
            boxShadow:"none"
          },
          "&.Mui-disabled":{
            border:"none"
          }
        },
        outlined:{
          border:`1px solid ${theme.palette.primary.dark}`
        }
      },
      },
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: "",
            backgroundColor: "",
            "&:hover": {
              backgroundColor: "",
            },
            "& .MuiListItemIcon-root": {
              color: "",
            },
          },
          "&:hover": {
            backgroundColor: "none",
            color: "",
            "& .MuiListItemIcon-root": {
              color: "",
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: "36px",
        },
        "&.Mui-selected": {
          color: "",
          backgroundColor: "",
          "&:hover": {
            backgroundColor: "",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: "",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "",
          "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: "100%",
            fontSize: "16px",
            fontWeight: 400,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          //   background: outlinedFilled ? bgColor : 'transparent',

          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            borderColor: "#E4E4E6",
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E4E4E6",
          },
        },
        input: {
          fontWeight: 400,
          // background: outlinedFilled ? bgColor : 'transparent',
          padding: "15.5px 14px",

          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
          "&:-webkit-autofill": {
            boxShadow: "0 0 0 100px #fff inset",
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {},
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "",
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: "4px",
        },
        valueLabel: {
          color: "",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            background: "",
            borderRadius: 4,
            color: "",
            ".MuiChip-deleteIcon": {
              color: "",
            },
          },
        },
        popper: {
          boxShadow:
            "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: "",
          background: "",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "",
            background: "none !important",
            "&:hover": {
              background: "none",
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: "",
          fontSize: "16px",
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: "",
          "& .MuiTabs-flexContainer": {
            borderColor: "",
          },
          "& .MuiTab-root": {
            color: "",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.dark,
          },
          "& .Mui-selected": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    //     MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       borderBottom: 0,
    //     },
    //   },
    // },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "12px 0 12px 0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "",
          "&.MuiTableCell-head": {
            fontSize: "0.875rem",
            color: theme.palette.grey[400],
            fontWeight: 500,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
        },
      },
    },
  };
}
